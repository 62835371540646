<template>
	<div class="containerx">
		<!-- <div class="btngrp">
      <div class="btn1" @click="Sbc(0)" :class="cekd? 'b_w1':'b_x1' ">环境设备</div>
      <div class="btn2" @click="Sbc(1)" :class="!cekd? 'b_w1':'b_x1' ">能源设备</div>
    </div>
    <div class="btngrp2">
      <div class="item" v-for="(item,index) in Data" :key="index" :class="item.cekd? 'b_w':'b_x' "
        @click="Sb(item,index)">{{item.name}}</div>
        <div class="item"
          @click="dialogFormVisible2 = true">新增</div>
    </div> -->
		<div>
			<el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect"
				background-color="#192534" text-color="#fff" active-text-color="#2BDEC9">
				<el-submenu index="1">
					<template slot="title">{{typeName}}</template>
					<el-menu-item index="0">环境设备</el-menu-item>
					<el-menu-item index="1">能源设备</el-menu-item>

				</el-submenu>
				<el-submenu index="2">
					<template slot="title">{{equipmentName}}</template>
					<el-menu-item :index="item.id.toString()" v-for="(item,index) in Data">{{item.name}}
					</el-menu-item>
				</el-submenu>
			</el-menu>
		</div>
		<div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
			<div class="title">安装步骤</div>
			<div style="display: flex;">
				<div class="add" @click="dialogFormVisible = true">新增步骤</div>
				<!-- <div class="back">返回</div> -->
			</div>
		</div>
		<div class="list">
			<draggable v-model="Data1" @change="log" :options="{animation:100}">
				<div class="item" v-for="(item,index) in Data1" :key="item.step">
					<div class="text">{{index+1}},{{item.step}}</div>
					<div class="btn-cz">
						<img @click="Xg(item)" style="margin-right: 2rem;" src="@/static/edit.png" />
						<img @click="open(item)" src="@/static/del.png" />
					</div>
				</div>
			</draggable>

		</div>

		<!-- 新增弹窗 -->
		<el-dialog title="新增步骤" :visible.sync="dialogFormVisible">
			<el-form :model="form">
				<el-form-item label="步骤:" :label-width="formLabelWidth">
					<el-input v-model="form.name" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="Addgy()">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 新增弹窗 -->
		<el-dialog title="修改步骤" :visible.sync="dialogFormVisible1">
			<el-form :model="form1">
				<el-form-item label="步骤:" :label-width="formLabelWidth">
					<el-input v-model="form1.name" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible1 = false">取 消</el-button>
				<el-button type="primary" @click="Addgy1()">修 改</el-button>
			</div>
		</el-dialog>
		<!-- 新增 -->
		<el-dialog title="新增设备" :visible.sync="dialogFormVisible2">
			<el-form :model="form2">
				<el-form-item label="设备名:" :label-width="formLabelWidth">
					<el-input v-model="form2.name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item v-for="(item,index) in form2.parameterList" label="参数:" :label-width="formLabelWidth">
					<el-input style="margin-bottom: 1rem; width: 30%; margin-right: 1rem;"
						v-model="form2.parameterList[index]" autocomplete="off"></el-input><span style="color: red;"
						@click="shanc(index)">删除</span>
				</el-form-item>
				<el-button type="primary" @click="addc">添加参数</el-button>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible2 = false">取 消</el-button>
				<el-button type="primary" @click="Addgy2()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import draggable from 'vuedraggable'
	import axios from 'axios'
	export default {
		data() {
			return {
				activeIndex2: '0',
				dialogFormVisible: false,
				dialogFormVisible1: false,
				dialogFormVisible2: false,
				formLabelWidth: '12rem',
				form: {
					name: ''
				},
				form1: {
					name: ''
				},
				form2: {
					"name": "",
					"parameterList": [
						'', ''
					],
					"type": 0
				},
				Cs: '',
				cekd: true,
				tableData: [{
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}, {
					name: 'XXXXXXXXXXXX公司'
				}],
				obj: {
					type: 0
				},
				Data: [],
				Data1: [],
				index_1: 0,
				Gy: null,
				Gy1: null,
				typeName: '环境设备',
				equipmentName: '选择设备'
			}
		},
		components: {
			draggable
		},
		created() {
			this.info()
		},
		methods: {
			handleSelect(key, keyPath) {
				// console.log(key + '====' + keyPath + "===");

				if (keyPath[0] == '1') {
					this.obj.type = key
					if (key == '0') {
						this.typeName = "环境设备"
					} else {
						this.typeName = "能源设备"
					}
					this.info()
				} else {
					if (key == '-999999') {
						this.dialogFormVisible2 = true
					} else {
						this.sbId = key
						this.Gy = key
						this.Data.forEach((equipment,index)=>{
							if(equipment.id == key){
								this.equipmentName = equipment.name
							}
						})
						this.info1(key)
					}

				}
			},
			log(e) {
				console.log(e, "2222");
				console.log(this.Data1);
				this.PX()
			},
			PX() {
				let _this = this
				let narr = []
				let narr1 = []
				for (let i = 0; i < _this.Data1.length; i++) {
					narr1.push(_this.Data1[i].sort)
				}
				this.sortNum(narr1);
				console.log(narr1, 'narr1!!!!');
				for (let i = 0; i < _this.Data1.length; i++) {
					let obj = {
						id: _this.Data1[i].id,
						sort: narr1[i]
					}
					narr.push(obj)
				}

				this.$newPost('/install/workmanship/sort', narr).then(res => {
					console.log(res, 'PX')
					if (res.code == 2000) {
						this.$message({
							message: res.message,
							type: 'success'
						});
						this.info()
					} else {
						this.$message({
							message: res.message,
							type: 'warning'
						});
					}
				})
			},
			sortNum(arr) {
				arr.sort(function(a, b) {
					return a - b;
				});
			},
			addc() {
				this.form2.parameterList.push('')
			},
			shanc(id) {
				this.form2.parameterList.splice(id, 1)
			},
			open(item) {
				this.$confirm('此操作将删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$ajax('delgy', 'delete', item.id).then(res => {
						console.log(res);
						if (res.code == 2000) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.info1(this.Gy)
						}
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			Xg(item) {
				this.form1.name = item.step
				this.Gy1 = item.id
				this.dialogFormVisible1 = true
			},
			Sb(item, index) {
				console.log(item, index, '点击');
				this.Data.forEach(item => {
					item.cekd = false
				})
				item.cekd = true
				this.Gy = item.id
				this.info1(this.Gy)
			},
			Sbc(id) {
				if (id == 0) {
					this.cekd = true
				} else {
					this.cekd = false
				}
				this.obj.type = id
				this.info()
			},
			info() {
				let url = '/equipment/type/list/'
				let that = this

				that.$newPost(url + that.obj.type).then((res)=>{
					that.Data = res.data

					let narr = {
						id: '-999999',
						name: '添加设备',
						type: that.obj.type
					}

					that.Data.push(narr)
					// console.log(that.Data);
					that.Gy = that.Data[0].id
					if(that.Data[0]!=null&&that.Data[0].name!=null&&that.Data[0].name!=''){
						that.equipmentName = that.Data[0].name
					}else{
						that.equipmentName = '选择设备'
					}

					that.info1(that.Gy)
				});
			},
			info1(id) {
				this.$ajax('cxgy', 'get', id).then(res => {
					// console.log(res.data, 'Data1!!!');
					this.Data1 = res.data
				})
			},

			Addgy() {
				if(this.Gy =='-999999'){
					this.$message({
						type: 'error',
						message: "添加失败，未选择具体设备类型！"
					});
					return;
				}
				let obj = {
					"equipmentTypeId": this.Gy,
					"step": this.form.name
				}

				this.$ajax('addgy', 'post', obj).then(res => {
					console.log(res);
					if (res.code == 2000) {
						this.$message({
							type: 'success',
							message: res.message
						});
						this.form = {
							name: ''
						}
						this.dialogFormVisible = false
						this.info1(this.Gy)
					}
					// this.Data1 = res.data
					// this.$message({
					//   type: 'success',
					//   message: res.m
					// });
				})
			},
			Addgy1() {
				let obj = {
					"id": this.Gy1,
					"step": this.form1.name
				}
				this.$ajax('edgy', 'post', obj).then(res => {
					console.log(res);
					if (res.code == 2000) {
						this.$message({
							type: 'success',
							message: res.message
						});
						this.form = {
							name: ''
						}
						this.dialogFormVisible1 = false
						this.info1(this.Gy)
					}else{
            this.$message({
            	type: 'error',
            	message: res.message
            });
          }
				})
			},
			Addgy2() {
				// this.form2.parameterList = this.Cs.split("，")
				this.form2.type = this.obj.type
				console.log(this.form2.parameterList, 'this.form2.parameterList!!!');
				// return
				this.$ajax('adsb', 'post', this.form2).then(res => {
					console.log(res);
					if (res.code == 2000) {
						this.$message({
							type: 'success',
							message: res.message
						});
						this.form = {
							"name": "",
							"parameterList": [],
							"type": null
						}
						this.dialogFormVisible2 = false
						this.info()
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.b_w1 {
		background-color: rgba(43, 201, 222, 0.6);
	}

	.b_x1 {
		background-color: rgba(43, 222, 201, 0.6);
	}

	.b_w {
		color: #2BC9DE;
	}

	.b_x {
		color: rgba(43, 222, 201, 0.6);
	}

	.containerx {
		color: #ffffff;
		overflow: auto;
		background-color: #192534;
		padding: 1rem 0;
	}

	.btngrp2 {
		margin-left: 1rem;
		margin-top: 3rem;
		// display: flex;
		align-items: center;

		.item {
			display: inline-block;
			// width: 10rem;
			// height: 4.6rem;
			line-height: 2rem;
			text-align: center;
			// border: 1px solid #2BC9DE;
			font-size: 2rem;
			// color: rgba(43, 222, 201, 0.6);
			text-decoration: underline;
			// background: rgba(43, 222, 201, 0.6);
			margin-right: 1.6rem;
			margin-bottom: 1rem;
			cursor: pointer;
		}

		.item:hover {
			background-color: rgba(117, 117, 117, 0.4);
		}

		.item-c {
			width: 10rem;
			height: 4.6rem;
			line-height: 4.6rem;
			text-align: center;
			border: 1px solid #2BC9DE;
			font-size: 1.6rem;
			// color: #FFFFFF;
			background-color: rgba(43, 222, 201, 0.6);
			margin-right: 1.6rem;
		}
	}

	.btngrp {
		margin-left: 1rem;
		margin-top: 1rem;
		display: flex;
		align-items: center;

		.btn1 {
			width: 10rem;
			height: 6.4rem;
			line-height: 6.4rem;
			text-align: center;
			// background: rgba(43, 201, 222, 0.6);
			font-size: 1.8rem;
			cursor: pointer;
		}

		.btn2 {
			width: 10rem;
			height: 6.4rem;
			line-height: 6.4rem;
			text-align: center;
			// background: rgba(43, 222, 201, 0.6);
			font-size: 1.8rem;
			margin-left: 3rem;
			cursor: pointer;
		}
	}

	.title {
		font-size: 1.8rem;
		font-weight: 500;
		margin-left: 2rem;
		//margin-top: 2rem;
		height: 5rem;
		line-height: 5rem;
	}

	.add {
		background: #2BC9DE;
		border-radius: 0.5rem;
		width: 9.5rem;
		height: 3.8rem;
		line-height: 3.8rem;
		text-align: center;
		font-size: 1.4rem;
		color: #FFFFFF;
		margin-right: 1rem;
	}

	.back {
		border: 1px solid #1CB8B8;
		border-radius: 0.5rem;
		width: 6.7rem;
		height: 3.8rem;
		line-height: 3.8rem;
		text-align: center;
		color: rgba(28, 184, 184, 1);
		font-size: 1.4rem;
		margin-right: 2rem;
	}

	.list {
    height: 60rem;
		width: 154.6rem;
		margin: 0 auto;
		margin-top: 1rem;
    overflow: auto;

		.item {
			width: 100%;
			height: 5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background:  rgba(162, 162, 162, 0.1);
		}

		.item:nth-child(2n) {
			background-color: rgba(14, 242, 242, 0.25);
		}

		.text {
			margin-left: 2rem;
			font-size: 1.4rem;
			width: 100rem;
			overflow: hidden;
		}

		.btn-cz {
			margin-right: 3rem;

			img {
				width: 3rem;
				height: 3rem;
			}
		}
	}
</style>
